import React from "react";
import Layout from "../components/Layout";
import styles from "../assets/css/technology.module.scss";
import { Link } from "gatsby-plugin-intl";
import { ReactSVG } from "react-svg";
import Seo from "../components/Seo";
import BannerTwoCol from "../components/Common/BannerTwoCol";
import InfoCircles from "../components/Common/InfoCircles";

const TecnologiaPage = () => {
	/*
					<ul>
					<li>
						<p>8K</p>
						<small>Usuarios</small>
					</li>
					<li>
						<p>24</p>
						<small>Países</small>
					</li>
					<li>
						<p>3</p>
						<small>Años</small>
					</li>
					<li>
						<p>45</p>
						<small>Snowgods</small>
					</li>
				</ul>
	 */

	const techCirclesInfo = [
		{
			label: "Usuarios",
			value: "8k",
		},
		{
			label: "Países",
			value: 24,
		},
		{
			label: "Años",
			value: 3,
		},
		{
			label: "Snowgods",
			value: 45,
		},
	];

	return (
		<Layout>
			<Seo title="Tecnología" />
			<section className={styles.technology}>
				<article
					className={styles.bgTechnology}
					style={{
						backgroundImage: "url('/technology/bg/technology-bg.jpg')",
					}}
				>
					<div className={styles.technologyWrapper}>
						<Link to="/" className="lineLink white">
							<u>Volver a la home</u>
						</Link>
					</div>
					<div className={styles.titleWrapper}>
						<h2>Nuestra tecnología</h2>
					</div>
				</article>

				<article className={styles.technologyInfo}>
					<InfoCircles items={techCirclesInfo} />
				</article>

				<article className={styles.technologyDetails}>
					<div className={styles.technologyText}>
						<h3>
							La aplicación diseñada para perseguir días perfectos en la
							nieve.
						</h3>
						<p>
							Algoritmos de predicción de alta montaña diseñados por
							esquiadores, inteligencia aumentada alimentada por
							feedbacks de usuarios, pronóstico extendido a 14 días en
							tres alturas y geolocalización de estaciones para ordenar
							resultados por cercanía y nieve. Y además una comunidad con
							usuarios expertos que suben reportes diarios. En Fresh Snow
							vas a encontrar un diseño donde lo difícil es sencillo.
						</p>
					</div>
					<div className={styles.list}>
						<ul>
							<li>
								<ReactSVG src="/technology/tech-icon-01.svg" />
								<h3>Análisis en tiempo real</h3>
								<p>
									Información actualizada al minuto, te enterarás de
									todos cambios del tiempo de último momento.
								</p>
							</li>
							<li>
								<ReactSVG src="/technology/tech-icon-02.svg" />
								<h3>Información en tres alturas</h3>
								<p>
									Toda la información del estado y pronóstico de la
									nieve, en tres niveles de altitud.
								</p>
							</li>
							<li>
								<ReactSVG src="/technology/tech-icon-03.svg" />
								<h3>Amplia cobertura</h3>
								<p>
									Toda la península ibérica, Pirineos, Alpes y resto de
									Europa
								</p>
							</li>
						</ul>
					</div>

					<BannerTwoCol
						direction="left"
						img="/technology/tech-img-01.jpg"
						title="Pronóstico extendido a 14 días"
						text="Disfruta de un pronóstico extendido de 14 días en 3 alturas y
						dividido en 3 franjas horarias totalmente gratis. Te ofrecemos
						un análisis de las condiciones hecho por nuestro algoritmo,
						dónde podrás saber rápidamente cómo está la estación. No
						encontrarás nada igual."
					/>

					<BannerTwoCol
						direction="right"
						img="/technology/tech-img-02.jpg"
						title="Valoraciones de los usuarios"
						text="Nuestros snowgods y la comunidad de usuarios de Fresh Snow suben reportes diariamente para darte la información más fresca y valiosa. ¡Forma parte tu también!"
					/>

					<BannerTwoCol
						direction="left"
						img="/technology/tech-img-03.jpg"
						title="Información detallada"
						text="Toda la información detallada de la estación, pistas, remontes, snowparks, distancia y estaciones cercanas para que puedas comparar las condiciones y elegir."
					/>
				</article>
			</section>
		</Layout>
	);
};

export default TecnologiaPage;
